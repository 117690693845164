<template>
  <div
    v-show="!item.hidden"
    class="menu-wrapper"
  >
    <template v-if="hasOneShowingChild && !(item.meta && item.meta.keepShow)">
      <app-link
        v-if="onlyOneChild.meta"
        :to="resolvePath(onlyOneChild.path)"
      >
        <el-menu-item
          :index="resolvePath(onlyOneChild.path)"
          :class="{ 'submenu-title-noDropdown': !isNest }"
          v-show="showItem(onlyOneChild)"
          @click="menuItemHandle"
        >
          <item
            :icon="onlyOneChild.meta.icon || (item.meta && item.meta.icon)"
            :title="onlyOneChild.meta.title"
          />
        </el-menu-item>
      </app-link>
    </template>

    <el-submenu
      v-else
      ref="subMenu"
      :index="resolvePath(item.path)"
      popper-append-to-body
      v-show="showItem(item)"
    >
      <template slot="title">
        <item
          v-if="item.meta"
          :icon="item.meta && item.meta.icon"
          :title="item.meta.title"
        />
      </template>
      <sidebar-item
        v-for="child in item.children"
        :key="child.path"
        :is-nest="true"
        :item="child"
        :base-path="resolvePath(child.path)"
        class="nest-menu"
      />
    </el-submenu>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import path from 'path';
import Item from './Item';
import AppLink from './Link';
import { isExternal } from '@/utils/validate';
import Cookies from 'js-cookie';
import qs from 'qs';
import md5 from 'md5';
import { getToken } from '@/utils/token';
export default {
  name: 'SidebarItem',
  components: { Item, AppLink },
  props: {
    // route object
    item: {
      type: Object,
      required: true,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // onlyOneChild: null,
    };
  },
  computed: {
    ...mapState('user', ['userInfo']),
    onlyOneChild() {
      const children = this.item.children ?? [];
      const showingChildren = children.filter((item) => !item.hidden);
      if (showingChildren.length > 1) {
        return {
          hide: true,
        };
      }
      return showingChildren[0] ? showingChildren[0] : { ...this.item, path: '', noShowingChildren: true };
    },
    hasOneShowingChild() {
      const { onlyOneChild } = this;
      return !onlyOneChild.hide && (!onlyOneChild.children || onlyOneChild.noShowingChildren) && !this.item.alwaysShow;
    },
  },
  methods: {
    // 埋点
    menuItemHandle() {
      if (process.env.VUE_APP_API_ENV == 'production') {
        let timestamp = new Date().getTime();
        let token = getToken(); //Cookies.get('token')
        let sign = md5(timestamp + token);
        let data = { menuId: this.item.meta.menuId, _TOKEN: token };
        this.$axios({
          url: `${process.env.VUE_APP_BASE_GATEWAY}/gsso/sso/addUserLookMenu`,
          method: 'post',
          data: qs.stringify(data),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            sign: sign,
            timestamp: timestamp,
            token: token,
          },
        });
      }
    },
    showItem(item) {
      if (!item.meta || !item.meta.roles || !item.meta.roles.length) {
        return true;
      }
      // 单账户控制权限
      if (item.meta.ids) {
        return (
          item.meta.roles.some((v) => this.userInfo.data.roles.includes(v)) &&
          item.meta.ids.includes(this.userInfo.data.id)
        );
      } else {
        return item.meta.roles.some((v) => this.userInfo.data.roles.includes(v));
      }
    },
    resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath;
      }
      if (isExternal(this.basePath)) {
        return this.basePath;
      }
      return path.resolve(this.basePath, routePath);
    },
  },
};
</script>
